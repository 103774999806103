import React from 'react'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function SidebarWidget ({ children, headline, linkLabel, link }) {

  return (
    <div className="sidebar__widget">
      
      {
        children
      }
        
      <div className="sidebar__widget__content">
        <h3 className="sidebar__widget__headline">{ headline } </h3>
        <Link className="sidebar__widget__link" to={ link }>{ linkLabel } ›</Link>
      </div>
    </div>
  )
}