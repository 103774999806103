import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export default function Widget ({ headline, text, link, label }) {

  return (
    <div className="widget">
      <div className="widget__image">
        {
          link === '/places'
          ? <StaticImage alt="" src='../images/widget-1.jpg' />
          : <StaticImage alt="" src='../../static/media/widget-candidates.jpg' />
        }
      </div>
      <div className="widget__content">
        <h3 className="widget__headline">{ headline }</h3>
        <p className="widget__text">{ text }</p>
        <Link to={link ? link : '/'} className="widget__link">{ label }</Link>
      </div>
    </div>
  )
}