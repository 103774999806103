import React from 'react';
import showdown from "showdown";
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

export default function Hero({ children, headline, subhead, video, videoMobile, intro, ctas, justify, size, page}) {
  const converter = new showdown.Converter();
  const htmlIntro = intro;

  const displayCtas = ( ctas ) => (
    <div className="hero__ctas">
      <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
        {
          ctas.map(cta => (
            <div key={ cta.button_label } className="col-md">
              {
                cta.button_url.includes('www') ?
                <a className="button" href={cta.button_url} target="_blank" rel="noopener noreferrer">
                  { cta.button_label }
                </a>
                :
                <Link className="button" to={cta.button_url}>
                  { cta.button_label }
                </Link>
              }
            </div>
          ))
        }

      </div>
    </div>
  )

  return (
    <div className={`hero hero--${size} hero--${page ? page : ''}`} >
      {
        video
          ? <>
              <div className="hero__background-video">
                <video autoPlay loop muted playsInline>
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              <div className="hero__background-video-mobile">
                <video autoPlay loop muted playsInline>
                  <source src={videoMobile} type="video/mp4" />
                </video>
              </div>
            </>
          : ''
      }
      { 
        // (!video && children.length) ? children[0] : children
        !video  && children
      }
      <div className={ `container d-flex flex-column ${ justify === 'center' ? 'justify-content-between' : '' }`}>
        

        <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
          <div className={`hero__headline-wrapper ${justify === 'center' ? 'col-md-12' : 'col-md-6'}`}>
            {
              subhead ? <h2 className="hero__subtitle">{ subhead }</h2> : ''
            }
            <h1 className="hero__headline">{ headline }</h1>
          </div>
          {
            children[1] ? children[1] : ''
          }
        </div>


        <div className={ `row ${ justify === 'center' ? 'justify-content-center text-center' : '' }`}>
          <div className={ `hero__intro-wrapper ${justify === 'center' ? 'col-md-8' : 'col-md-6'}` }>
            
              { intro ? <ReactMarkdown className="hero__intro">{htmlIntro}</ReactMarkdown> : '' }

              {
                ctas ? displayCtas( ctas ) : ''
              }

          </div>
        </div>

      </div>
    </div>
  )
}