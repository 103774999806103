import axios from "axios"

export const saveSignupForm = async (data) => {
  try {
    const response = await axios.post('/api/signup-form', data);
    if (response.status === 200) {
      return {
        success: true,
        result: 'success',
        msg: 'Thank you for subscribing!'
      };
    }
  } catch (error) {
    console.error('Error saving data to Google Sheets:', error);
    return null;
  }
}