import * as React from "react"

export default function IconSidebar() {
  return (
    <svg 
      width="8" 
      height="4" 
      viewBox="0 0 8 4" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M4 4L0.535898 0.250001L7.4641 0.25L4 4Z" 
        fill="black"/>
    </svg>
  )
}