import * as React from "react"

import SignUpForm from '../components/forms/SignUpForm'


export default function SignUpSection () {
  return(
    <section className="page-signup">
      <div className="container">
        <div className="row">

          <SignUpForm heading="Get updates about the Ward elections" />

        </div>
      </div>
    </section>
  )
}