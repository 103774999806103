import * as React from "react"

export default function IconLink() {
  return (
    <svg
      width="12" 
      height="12" 
      viewBox="0 0 12 12" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0v12h12V9h-1.5v1.5h-9v-9H3V0H0zm6 0l2.25 2.25L4.5 6 6 7.5l3.75-3.75L12 6V0H6z" fill="#E7513D"/>
    </svg>
  )
}