import React, { useState } from 'react'
import { Link } from "gatsby"
import IconLink from "./icons/IconLink"
import IconSidebar from './icons/IconSidebar';

const DropDownMenu = ({ links, handleClick, menuDisplay }) => (
  <div className="dropdown">
    <a onClick={ handleClick } className={`dropdown__toggle ${menuDisplay ? 'show' : ''}`} role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
      Navigate to
      <IconSidebar />
    </a>

    <ul id="dropdown-menu" className={`dropdown-menu ${menuDisplay ? 'show' : ''}`} aria-labelledby="dropdownMenuLink">
      { 
        links.map( link => 
          <li key={ link.link_label }>
            <Link className="dropdown-item" to={ link.link_url }>
              <IconLink />
              { link.link_label }
            </Link>
          </li> 
        )
      }
    </ul>
  </div>
)

const LinkNav = ({ links }) => (
  <div className="list-wrapper">
    <h5 className="list__headline">Direct links:</h5>
    <ul className="sidebar__list sidebar__list--external">
      { links.map( link => 
        <li key={ link.link_label }>
          <Link to={ link.link_url }>
            <IconLink />
            { link.link_label }
          </Link></li> 
      )}
    </ul>
  </div>
)

export default function Sidebar ({ children, headings, links }) {
  const [ menuDisplay, setMenuDisplay ] = useState(false);

  
  // = = = = = = = = = = = = =
  // Event Listeners

  //
  const handleClick = () => {
    setMenuDisplay(!menuDisplay);
  }

  const DesktopVersion = () => (
    <div className="sidebar sidebar--desktop">
      {
        headings?.length > 1 ?
          <div className="list-wrapper">
          <h5 className="list__headline">Jump to section</h5>
          <ul className="sidebar__list sidebar__list--internal">
            { headings.map( heading => <li key={ heading.value }><Link to={`#${ heading.value }`}>{ heading.value }</Link></li> )}
          </ul>
        </div>
        
        : ''
      }

      { children }

      { 
        links?.length ? <LinkNav links={ links } /> : ''
      }
    </div>
  )

  const MobileVersion = () => (
    <div className="sidebar sidebar--mobile">
      { children }
      { links ? <DropDownMenu links={ links } handleClick={ handleClick } menuDisplay={ menuDisplay } /> : '' }
    </div>
  )

  return (
    <>
      <MobileVersion />
      <DesktopVersion />
    </>
  )
}